import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Growth is Math → Art → Science`}</h1>
    <p><strong parentName="p">{`Today is all about growth.`}</strong></p>
    <p><strong parentName="p">{`Two types of growth:`}</strong></p>
    <ol>
      <li parentName="ol">{`e-Commerce`}</li>
      <li parentName="ol">{`Audience`}</li>
    </ol>
    <p>{`Let’s start with ecom.`}</p>
    <p>{`My wife has an ecommerce store, I’ve talked about it loosely before, but never
in detail. She doesn’t like me sharing much.`}</p>
    <p>{`But I promised her I’d help her grow her store for the next couple weeks.`}</p>
    <p>{`So i'm gonna show you how i grow it, while keeping some specifics out of the
updates, because i wanna respect her wishes to keep it her business to share,
not mine.`}</p>
    <p>{`Her store launched just 4 months ago, and i think there's a ton of room to grow.`}</p>
    <p>{`And, if it's successful, it lets my wife have a business that's her passion.`}</p>
    <p>{`And....she's already nailed the product, but i wanna use some of the lessons i
know online and apply them here to try and grow it.`}</p>
    <p><strong parentName="p">{`So, here’s how we approached today:`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Setup calls with 4 people who’ve been doing ecom for awhile`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Looked at the core health of the business`}</strong></p>
      </li>
    </ol>
    <p><strong parentName="p">{`When I look at a business, I ask the following questions:`}</strong></p>
    <ul>
      <li parentName="ul">{`Monthly revenue, is it growing or flat, show me the last 6 months on a line
chart.`}</li>
      <li parentName="ul">{`How much are you spending on ads as a % of revenue? (~20% is great, 30% is ok,
45%+ is ew)`}</li>
      <li parentName="ul">{`What is your ROAS (for every dollar you spend on ads, how much revenue is
generated?)`}</li>
      <li parentName="ul">{`Are the gross margins (Revenue - Cost of Goods Sold - Discounts) >70%?`}</li>
      <li parentName="ul">{`What is the repeat purchase rate (if they bought once, do they buy again?).
20% is ok, 30% good, 40%+ great .`}</li>
    </ul>
    <p><strong parentName="p">{`3. Next - it’s time to grow. So to start, Ben created an “experiment board?”
for us to brainstorm on.`}</strong></p>
    <p>{`Every growth idea is scored on potential `}<strong parentName="p">{`Impact, Cost (effort or `}{`$`}{`), and
Likelihood of success.`}</strong></p>
    <p>{`For example, our email popup (‘hey put your email in for 10% off!’) is doing
poorly right now, converting <5% of people who see it. I know from friends that
this number should be 10%+.`}</p>
    <p>{`We get a decent amount of landing page visitors per month, so if we could move
it from 5% → 10% that would translate into a bunch of extra emails every month
we can use for ongoing marketing. We haven’t tried anything to improve it yet,
so I’m sure there is some easy stuff we can tweak to make it better.`}</p>
    <p>{`So I rank that as high impact, low effort, and high likelihood of success.`}</p>
    <p>{`30 minute brainstorm, here’s what we got on our board:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "791px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHYsCBH/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAQABBQJTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwFkf//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABgQAAIDAAAAAAAAAAAAAAAAAAABEBFh/9oACAEBAAE/IXexQ//aAAwDAQACAAMAAAAQg8//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAAGH/2gAIAQIBAT8QSf/EABsQAAICAwEAAAAAAAAAAAAAAAABESExUXGR/9oACAEBAAE/EJcKnFA7t5Ejfp//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Growth Experiments Brainstorm",
          "title": "Growth Experiments Brainstorm",
          "src": "/static/52e53cfb95ed4859125d8b545a97035a/9d0b7/growth-experiments-brainstorm.jpg",
          "srcSet": ["/static/52e53cfb95ed4859125d8b545a97035a/a6407/growth-experiments-brainstorm.jpg 400w", "/static/52e53cfb95ed4859125d8b545a97035a/9d0b7/growth-experiments-brainstorm.jpg 791w"],
          "sizes": "(max-width: 791px) 100vw, 791px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`To get momentum, we started with one with a high score and low cost (aka quick
to try it), and gave it a go: “change email popup to boost capture rate from 5%
→ 10%”.`}</p>
    <p>{`Every experiment we run, will have a simple template:`}</p>
    <p><strong parentName="p">{`Name:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`Problem:`}</strong></p>
    <p><strong parentName="p">{`Proof the problem exists:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`Solution:`}</strong></p>
    <p><strong parentName="p">{`Goal:`}</strong></p>
    <p><strong parentName="p">{`Size of the prize:`}</strong></p>
    <p>{`^ we do this so that it’s `}<strong parentName="p">{`clear`}</strong>{` what we’re doing and `}<strong parentName="p">{`measurable.`}</strong>{` It
takes 5 mins to do this step, but very few people do it. I’m telling ya, it’s a
life saver.`}</p>
    <p>{`Half the time you write this out, and realize the experiment is not worth doing
(small size of prize) or that it’s really just a pet idea, and not solving a
real problem (cant find proof the problem exists)`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://click.convertkit-mail2.com/27uw9oz5z8uoh686x9s3/dpheh0hqzwqlndcm/aHR0cHM6Ly9kb2NzLmdvb2dsZS5jb20vc3ByZWFkc2hlZXRzL2QvMVNUbWd5ZUE5V3pEY2EwM0w0b2IwN0JnYTV3OEduU3ZxeUxpRDBsUEZhaEEvZWRpdD91c3A9c2hhcmluZw=="
        }}>{`If you wanna use our templates for these, click here to get 'em!`}</a></strong></p>
    <p>{`For example, here’s Ben’s experiment for today:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Operation`}</strong>{` EmailMONSTER`}</li>
      <li parentName="ul"><strong parentName="li">{`Problem:`}</strong>{` We only have a few thousand customer emails for marketing even
tho we’ve had a lot of visitors in the last 120 days`}</li>
      <li parentName="ul"><strong parentName="li">{`Proof:`}</strong>{` Last 30 days, our email capture rate is < 5% of those who saw the
popup. My advisors say this should be 10-15%.`}</li>
      <li parentName="ul"><strong parentName="li">{`Solutions:`}</strong>{` Make the popup juicier. Add a fun picture & try making the
offer more tangible (eg. say “`}{`$`}{`5 off” instead of “10% off”)`}</li>
      <li parentName="ul"><strong parentName="li">{`Goal:`}</strong>{` Increase conversion from 4.8% --> 10%+`}</li>
      <li parentName="ul"><strong parentName="li">{`Size of the Prize:`}</strong>{` Extra emails collected per month will increase ROAS and
revenue`}</li>
    </ul>
    <p>{`Our goal is to ship this whole experiment today. Most experiments should take :`}</p>
    <ul>
      <li parentName="ul">{`1 hour to come up with + get the data`}</li>
      <li parentName="ul">{`1 day to ship it`}</li>
      <li parentName="ul">{`~3-5 days to collect data if it’s working or not (while you ship the next
experiment)`}</li>
    </ul>
    <p>{`The last thing we wanted to share today is a call we did with our friend who is
doing > `}{`$`}{`20M/year on his ecom store.`}</p>
    <p><strong parentName="p">{`I basically said: “here’s the situation, tell me what I’m doing wrong”. Here’s
what he said:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`You're not collecting enough info about potential/current customers:`}</strong>{` Get
their email (10%+), sms, + name, birthday etc... Otherwise you’re wasting your
ad dollars`}</li>
      <li parentName="ul"><strong parentName="li">{`Your FB Ads ROAS is too low:`}</strong>{` You need more campaigns focused on
retargeting existing customers, no brainer`}</li>
      <li parentName="ul"><strong parentName="li">{`Work on your Upsells + Email campaigns`}</strong>{` - checkout the 1ClickUpsell app to
boost your average order value (AOV) by 10-20%. And your emails are weak right
now, email should account for at least 15% of your monthly revenue.`}</li>
      <li parentName="ul"><strong parentName="li">{`Go ask your customers`}</strong>{` - why did you decide to give us a try? Then take
that language and use it in your ads. Customers are the best source of ad
copy.`}</li>
    </ul>
    <p><strong parentName="p">{`I also wanted to share some of my questions I asked him. Ask a better
question, get a better answer. Here are some of the good (non-traditional)
questions that I asked:`}</strong></p>
    <ol>
      <li parentName="ol">{`Pretend you were buying my company, what would be the first 3 pieces of data
you’d be looking into?`}</li>
      <li parentName="ol">{`You know a lot of stores...what store should we look at for inspiration?
Who’s like us...but just further along?`}</li>
      <li parentName="ol">{`Which metric on this dashboard stands out to you, good or bad.`}</li>
      <li parentName="ol">{`If I was only going to do 2 things this week - what two should I focus on?`}</li>
      <li parentName="ol">{`Tactical questions like, “What’s the easiest/no genius required way to move
this metric”`}</li>
    </ol>
    <p><strong parentName="p">{`Growth is “Math → Art → Science”.`}</strong></p>
    <p><strong parentName="p">{`Math`}</strong>{` to figure out where the problem is`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Art`}</strong>{` to sketch out a possible solution`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Science`}</strong>{` to test & see if your solution works or not.`}</p>
    <p><strong parentName="p">{`Get good at all 3.`}</strong></p>
    <p>{`-shaan`}</p>
    <p>{`P.S.
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/27uw9oz5z8uoh686x9s3/e0hph7hk9wk8o6s8/aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g_dj14X1RDS3JUamYwWQ=="
      }}><strong parentName="a">{`watch this video`}</strong></a>{`
from the founder of teachable about growth, if you wanna see another way to
think about it`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/1STmgyeA9WzDca03L4ob07Bga5w8GnSvqyLiD0lPFahA/edit#gid=0',
        label: 'AAP Growth Templates Google Sheet'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://www.youtube.com/watch?v=x_TCKrTjf0Y',
        label: 'Ankur Nagpal of Teachable @ Hustle Con'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.product_samples_are_on_the_way.label,
      slug: slugify(postMap.product_samples_are_on_the_way.slug)
    }} nextButton={{
      text: postMap.the_art_of_non_violent_communication.label,
      slug: slugify(postMap.the_art_of_non_violent_communication.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      